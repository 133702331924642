<template>
  <div :redraw="redraw">
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th rowspan="3"></th>
        <th colspan="12">
          {{ $t("informationAboutStudentLeaving.leavingReason") }}
        </th>        
      </tr>
      <tr>
        <th colspan="4">
          {{ $t("informationAboutStudentLeaving.parentsMovement") }}
        </th>   
        <th colspan="3">
          {{ $t("informationAboutStudentLeaving.transfer") }}
        </th>
        <th rowspan="2">
          {{ $t("informationAboutStudentLeaving.homeCircumstances") }}
        </th>
        <th rowspan="2">
          {{ $t("informationAboutStudentLeaving.longTermIllness") }}
        </th>
        <th rowspan="2">
          {{ $t("informationAboutStudentLeaving.expulsionFromSchool") }}
        </th>
        <th rowspan="2">
          {{ $t("informationAboutStudentLeaving.otherReasons") }}
        </th>
        <th rowspan="2">
          {{ $t("informationAboutStudentLeaving.all") }}
        </th>
      </tr>
      <tr>
        <th>
          {{ $t("informationAboutStudentLeaving.outsideTheRepublic") }}
        </th>
        <th>
          {{ $t("informationAboutStudentLeaving.outsideTheOblast") }}
        </th>
        <th>
          {{ $t("informationAboutStudentLeaving.outsideTheRaion") }}
        </th>
        <th>
          {{ $t("informationAboutStudentLeaving.insideTheRaion") }}
        </th>
        <th>
          {{ $t("informationAboutStudentLeaving.school") }}
        </th>
        <th>
          {{ $t("informationAboutStudentLeaving.schoolTooFar") }}
        </th>
        <th>
          {{ $t("informationAboutStudentLeaving.work") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(dataItem, mIndex) in data" :key="mIndex">
        <td>
          {{ $t("informationAboutStudentLeaving.quarter." + dataItem.title) }}
        </td>
        <td>
          {{showData(dataItem.data.outsideTheRepublic)}}
        </td>
        <td>
          {{showData(dataItem.data.outsideTheOblast)}}
        </td>
        <td>
          {{showData(dataItem.data.outsideTheRaion)}}
        </td>
        <td>
          {{showData(dataItem.data.insideTheRaion)}}
        </td>
        <td>
          {{showData(dataItem.data.school)}}
        </td>
        <td>
          {{showData(dataItem.data.schoolTooFar)}}
        </td>
        <td>
          {{showData(dataItem.data.work)}}
        </td>
        <td>
          {{showData(dataItem.data.homeCircumstances)}}
        </td>
        <td>
          {{showData(dataItem.data.longTermIllness)}}
        </td>
        <td>
          {{showData(dataItem.data.expulsionFromSchool)}}
        </td>
        <td>
          {{showData(dataItem.data.otherReasons)}}
        </td>
        <td>
          {{showData(dataItem.data.all)}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>  
</template>
<style>
.dropped td {
  background-color: #F64E60;
}

th.rotate {
  writing-mode: vertical-rl;
}

</style>
<script>
import ApiService from "@/common/api.service";

export default {
  name: "InformationAboutStudentLeavingTable",
  components: {
  },
  data() {
    return {
      data:[],
      groupId: -1,

      redraw: 0
    };
  },
  mounted() {
      
  },
  methods: {
    redrawTable(groupId){
      this.groupId = groupId;
      let $this = this;
      
      ApiService.querySecured("schoolReports/informationAboutStudentLeaving", {
        params: { groupId: groupId }
      }).then(({data}) => {
        $this.data = [];
        
        $this.addData("1", data.quarter1);
        $this.addData("2", data.quarter2);
        $this.addData("3", data.quarter3);
        $this.addData("4", data.quarter4);
        $this.addData("yearly", data.yearly);
        
        
        $this.redraw = Math.random();
      }).catch((e) => {
        alert(e);
      });
    },
    addData(title, dataItem){
      this.data.push(
          {
            title: title,
            data: dataItem
          }
      );
    },
    showData(data){
      return data > 0 ? data : '';
    }
  }
};
</script>
